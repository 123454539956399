@import '../../styles/variables';

.main-header {
    
    position: absolute;
    width: 100%;
    top:0;
    z-index: 9998;
    height: 75px;
    display: flex;
    align-items: center;
    background: transparent;

    .container{
        .row{
            justify-content: space-between;

            @include bp('mbtb') {
                flex-wrap: nowrap;
            }

            *{
                display: flex;
                align-items: center;
            }

            .lang{
                justify-content: flex-end;
                color: $black;
                // div { position: fixed; }
                button {
                    font-family: $body-font;
                    font-weight: 800;
                    border: none;
                    background: none;
                    color: $black;
                    transition: font-size .3s, opacity .3s;
                    line-height: 1;
                    padding-left: .3em;
                    padding-right: .3em;
                    font-size: 1.1em;
                    opacity: .8;
                    &.selected {
                        font-size: 1.6em;
                        pointer-events: none;
                        opacity: 1;
                        // display: none;
                    }
                }
            }

            .logo {
                align-items: center;
                justify-content: start;
                text-align: left;
                img {
                    // oreo
                    max-height: 60px; 
                }

                @include bp('mb') {
                    img{
                        // oreo
                        max-height: 60px;
                    }
                }
            }

            .menu{

                button{
                    // position: fixed;
                    background: transparent;
                    border: none;
                    color: white;
                    outline: none;

                    

                    img{
                        max-width: 54px;
                        height: auto;

                        @include bp('mb') {
                            max-width: 32px;
                        }

                        &:last-child {
                            display: none;
                        }

                        .nav-opened + & {
                            &:first-child { display: none; }
                            &:last-child { display: block; }
                        }
                    }
                }
            }
        }
    }

}

.nav-wrapper {
    position: fixed;
    background: transparentize($black,.5);
    // fill-opacity:40%;
    backdrop-filter: blur(14px);
    width: 100%;
    height: 100%;
    transition: all .3s ease;
    z-index: 9997;
    max-width: 100%;
    top: 0;

    .container {
        height: 64px; // match header
        
        button {
            border: none;
            background: none;
            line-height: 64px;
            img {
                max-width: 44px;
                height: auto;
            }
        }
        
    }

    &.nav-closed{
        transform: translate3d(0, -100%, 0);
        opacity: 0;
        .nav-container { display: none; }
    }

    &.nav-opened{
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }

    .nav-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        ul.nav-list{

            img { width: 365px; max-width: 100%; margin: 1em auto;}
            
            @media screen and (max-height: 640px) {
                margin-top: 30px;
            }

            .nav-list__item{
                list-style: none;
                text-align: center;
                margin-bottom: 2em;
        
                a{
                    font-family: $heading-font;
                    font-size: 1.5em;
                    font-weight: 500;
                    color: $cream;
                    text-align: center;
                    transition: transform .3s;
                    display: inline-block;

                    @include bp('mb') {
                        // max-width: 56%;
                    }
        
                    &:hover{
                        color: $white;
                        text-decoration: none;
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

@include bp('mbtb') {
    .nav-wrapper.nav-opened {
        height: 100vh;
        width: 100vw;
    }
    // .nav-wrapper .nav-container ul.nav-list .nav-list__item,
    // .nav-wrapper .nav-container ul.nav-list .nav-list__item a {text-align: left;}
}
