@import '../variables';

.main-wrapper{

    
    .home {
        // h3, h4 {
        //     font-family: $heading-font;
        // }
        h2, h4 {
            font-size: 4.6em;
        }
        h3 {
            font-family: $body-font;
            font-size: 1.15em;
            font-weight: 800;
        }
        p {
            text-transform: uppercase;
        }

        .comingsoon-body {
            line-height: 1;
        }
        
        .besties-hero {
            margin: -80vw -100% -95vw;
            width: 300%;
            padding-bottom: 280%;
            position: relative;
            @include bp('tb') {
                margin: -24em -100% -32em;
            }
            @include bp('dt') {
                margin: -14em -100% -17em;
            }
            @include bp('lg') {
                margin: -18em -100% -23em;
            }
            @include bp('xl') {
                margin: -21em -100% -28em;
            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                left: 0;
                top: 0;
                transform-origin: 50% 38%;
            }
            span {
                position: absolute;
                left: 48.7%;
                top: 42%;
                color: #fff;
                font-size: .5em;
                transform: rotateX(3deg) rotateY(45deg);
            }
        }
        &.coming-soon .besties-hero {
            @include bp('tb') {
                margin: -24em -100% -32em;
            }
            @include bp('dt') {
                margin: -17em -100% -21em;
            }
            @include bp('lg') {
                margin: -22em -100% -29em;
            }
            @include bp('xl') {
                margin: -27em -100% -35em;
            }
        }

        .row { 
            margin-bottom: 2em;
            position: relative;
        }

        &.home {
            .row:first-child {
                margin-bottom: 10em;

                p { 
                    font-size: 1.5em;
                    font-weight: 800;
                    // line-height: 2;
                    margin-bottom: 1em;
                    b { font-style: italic; }
                }
                span {
                    font-size: 1.5em;
                    font-weight: 800;
                    text-transform: uppercase;
                    text-align: center;
                    background-image: url(../../images/chevron.png);
                    background-position: bottom center;
                    background-repeat: no-repeat;
                    padding-bottom: 1.5em;
                }
            }
        }

        

        #cookie-stack {
            @include bp('tbdt') {
                .col-md-3 {
                    h2 { margin-right: -3em; }
                    p { margin-right: -12em; }
                }
            }
            img { margin-left: 6em; }
        }

        .tease-vid {
            video {
                border: 1px solid $gold;
                cursor: pointer;
                &:focus {
                    outline: 2px dotted #fff;
                }
            }

            &:has(video:not(.playing)) {
                &:after {
                    content: url(../../images/vid-play.png);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -65px;
                    margin-top: -65px;
                    pointer-events: none;

                    @include bp('mb'){
                        transform: scale(.5);
                    }
                }
            }
        }
    }
    
    .home-bottom {
        background-color: $black;
        color: $white;
        h2, h4 {
            font-size: 4.6em;
        }
        p {
            text-transform: uppercase;
        }

        .row { margin-bottom: 4em; }

        #wall {
            background-image: url(../../images/bottle-pattern.png);
            background-position: top;
            background-size: 29px auto;
            background-repeat: repeat-x;
            padding-top: 7em;
        }
        #wall .col-12:first-child {
            display: grid;
            grid-template-areas: 
                "img1 img2"
                "img5 img6"
                "img3 img3"
                "img3 img3";
            @include bp('tbdt') {
                
                grid-template-areas: 
                    "img1 img2 img4 img3 img3"
                    "img5 img6 img7 img3 img3";
            }
            gap: 1em;
            
            img { 
                max-width: 100%;
                object-fit: cover;
                aspect-ratio: 1;
            }
            img:nth-child(1) { grid-area: img1; }
            img:nth-child(2) { grid-area: img2; }
            img:nth-child(3) { grid-area: img3; }
            img:nth-child(4) { grid-area: img4; }
            img:nth-child(5) { grid-area: img5; }
            img:nth-child(6) { grid-area: img6; }
            img:nth-child(7) { grid-area: img7; }
            @include bp('mb') {
                img:nth-child(4), img:nth-child(7) { display: none; }
            }

        }
    }

    #bubs-0 {
        left: 65%;
        top: 63%;
    }
    #bubs-1 {
        right: -16em;
        top: -6em;
        transform: scale(.6);
    }
    #bubs-2 {
        right: -12em;
        bottom: -12em;
    }
    #bubs-3, #bubs-5 {
        left: -16em;
        transform: scale(1.3);
    }
    #bubs-4, #bubs-6 {
        right: -16em;
        bottom: -12em;
        transform: scale(1.5);
    }
    #bubs-5 {
        left: -20em;
    }
    #bubs-6 {
        right: -18em;
        bottom: -8em;
    }

    @include bp('mb'){
        #bubs-4 { display: none; }
        #bubs-3, #bubs-5 {
            transform: scale(1.1);
            left: -8em;
            bottom: -2em;
        }
        #bubs-6 {
            transform: scale(1.3);
            right: -8em;
        }
    }
}




//french version
.fr-CA{
    
}

.fullwidth {
    width: 100vw;
    margin: 0 -7.25rem;
    padding: 3rem;
}
