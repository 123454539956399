@import './fonts.scss';

$cream: #f1d3b5;
$grey : #828282;
$white : #ffffff;
$black: #000000;
$charcoal: #101010;
$blue: #0058C9;
$lightblue: #00c0f1;
$lightblue-rollover: #7fe5ff;
$red: #ff0000;
$red-rollover: #fd646a;
$gold: #d4a97e;


//fonts
$heading-font : "BlueScreen", sans-serif;
$body-font : "Norms", sans-serif;
$legal-font : "Pluto", sans-serif;

@mixin bp( $point ) { // breakpoints
    @if $point == mb {
        @media ( max-width: 575.9px ) { @content; }
    }
    @else if $point == mbtb {
        @media ( max-width : 767.9px ) { @content; }
    }
    @else if $point == tb {
        @media ( min-width : 576px ) and ( max-width: 767.9px ) { @content; }
    }
    @else if $point == tbdt {
        @media ( min-width : 576px ) { @content; }
    }
    @else if $point == dt { // match bootstrap
        @media ( min-width : 768px ) { @content; }
    }
    @else if $point == lg { // match bootstrap
        @media ( min-width : 992px ) { @content; }
    }
    @else if $point == xl { // match bootstrap
        @media ( min-width : 1200px ) { @content; }
    }
    @else if $point == xxl {
        @media ( min-width : 1920px ) { @content; }
    }
}