@font-face {
    font-family: 'Pluto';
    src: url('/fonts/hvdtrial_pluto-regular.woff') format('woff'), url('/fonts/hvdtrial_pluto-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Norms';
    src: url('/fonts/tt_norms_pro_regular.woff') format('woff'), url('/fonts/tt_norms_pro_regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Norms';
    src: url('/fonts/tt_norms_pro_extrabold-webfont.woff') format('woff'), url('/fonts/tt_norms_pro_extrabold-webfont.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'BlueScreen';
    src: url('/fonts/tt-bluescreens-trial-extrabold.woff') format('woff'), url('/fonts/tt-bluescreens-trial-extrabold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}