.bubble-group {
    position: absolute;
    background-image: url(../images/bubble-2.svg);
    background-repeat: no-repeat;
    background-size: 11% auto;
    background-position: 28% 50%;
    width: 423px;
    height: 392px;
    animation: bpop2 3s infinite both;
    z-index: -1;

    &:after {
        content: '';
        position: absolute;
        background-image: url(../images/bubble-1.svg);
        background-size: 100% auto;
        width: 13%;
        height: 14%;
        left: 53.5%;
        animation: bpop1 3.5s infinite both;
    }
    &:before {
        content: '';
        position: absolute;
        background-image: url(../images/bubble-3.svg);
        background-size: 100% auto;
        width: 21%;
        height: 23%;
        left: 50%;
        top: 65%;
        animation: bpop3 2.5s infinite both;
    }
}
.bubble-group.delay1 {
    animation-delay: .8s;
    &:after{ animation-delay: .8s; }
    &:before{ animation-delay: .8s; }
}
.bubble-group.delay2 {
    animation-delay: 1.4s;
    &:after{ animation-delay: 1.4s; }
    &:before{ animation-delay: 1.4s; }
}

@keyframes bpop2 {
    0% { 
        background-size: 0%;
        background-position: 29% 61%;
    }
    5% { 
        background-size: 11% auto;
        background-position: 28% 60%;
    }
    85% { 
        background-size: 11% auto;
        background-position: 28% 30%;
    }
    90% { 
        background-size: 15% auto;
        background-position: 27% 29%;
    }
    90.1% {
        background-size: 0%;
        background-position: 29% 31%;
    }
    100% {
        background-size: 0%;
    }
}
@keyframes bpop1 {
    0% { 
        transform: translateY(30px) scale(0);
    }
    5% { 
        transform: translateY(30px) scale(1);
    }
    85% { 
        transform: translateY(-30px) scale(1);
    }
    90% { 
        transform: translateY(-30px) scale(1.2);
    }
    90.1% {
        transform: translateY(-30px) scale(0);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes bpop3 {
    0% { 
        transform: translateY(20px) scale(0);
    }
    5% { 
        transform: translateY(20px) scale(1);
    }
    85% { 
        transform: translateY(-80px) scale(1);
    }
    90% { 
        transform: translateY(-80px) scale(1.2);
    }
    90.1% {
        transform: translateY(-80px) scale(0);
    }
    100% {
        transform: scale(0);
    }
}