@import './variables';
@import './animation';

body{
    font-family: sans-serif;
    background: $white;
    line-height: 1.2;
    
    background-size: 100vw auto;
    background-repeat: repeat-y;
    background-position: top;
    &:not(:has(.quiz)){
        // background-image: url('../images/stars.png');
    }
    @include bp('mb') {
        background-size: 200% auto;
        background-position-y: -50vw;
    }
    
}

.skip-to-content-link {
    position: absolute;
    transform: translateY(-100%);
    z-index: 9999;
    padding: .5em;
    font-family: $body-font;
    color: $white;
    background: $blue;

    &:focus {
        transform: translateY(0%);              
    }
}

.main-wrapper{
    // margin-top: 64px;
    // padding-top: 64px;
    overflow-x: clip;
    
    position: relative;
    z-index: 1;
    color: $black;
    // min-height: calc(100vh - 120px);

    font-family: $body-font;
    h1, h2, h3, h4, h5, h6 { font-family: $heading-font; line-height: 1; text-transform: uppercase; }
    // h1 { font-size: 3.75em; }
    // h2 { font-size: 1.8em; }
    // h2 { letter-spacing: 0.2em; }
    p { font-size: 1.15em; line-height: 1.5; }


    > .container-fluid, > .container { 
        padding-top: 8rem;
        padding-bottom: 8em;
        position: relative;
        .row {  }
    }

    .dvl-title + h2 {
        margin-top: .5em;
    }

}
.main-wrapper a:not(.button)  {
    text-decoration: underline;
    color: $white;
}
.main-wrapper.rules a:not(.button) {
    color: $blue;
}

.button {
    border-radius: 2em;
    line-height: .8;
    // width: 300px;
    padding: .9em 2em;
    font-weight: 700;
    font-size: 1.5em;
    display: inline-block;
    color: $white;
    box-sizing: border-box;
    position: relative;
    text-decoration: none;
    transition: background-color .3s ease;

    border: none;

    background: $blue;
    text-align: center;
    text-transform: uppercase;
    // background-clip: padding-box;
    // border: solid 2px transparent;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0; right: 0; bottom: 0; left: 0;
    //     z-index: -1;
    //     margin: -2px;
    //     border-radius: inherit;
    //     background: linear-gradient(to right, $blue, $purple);
    // }
    
    &:hover {
        color: $blue;
        background-color: $white;
        text-decoration: none;
        // -webkit-filter: drop-shadow(0px 0px 6px $white);
        // filter: drop-shadow(0px 0px 6px $white);
    }
    &[disabled], &.disabled {
        background-color: $grey;
        pointer-events: none;
    }
    
    &.info-form__submit-btn {
        display: block;
        margin: 0 auto;
    }

    &.darkside {
        background-color: $red;
        &:hover {
            background-color: $red-rollover;
        }
    }
}

.slick-arrow {
    // background-repeat: no-repeat;
    
    height: 40px;
    &:before { display: none; }
    &.slick-next {
        background: url(../images/arrow-right.png) no-repeat center;
    }
    &.slick-prev {
        background: url(../images/arrow-left.png) no-repeat center;
    }
    // &:hover, {
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: center;
    // }
}


.fr-CA {
    .button {
        // font-size: 1.8em;
        // line-height: 1em;
        max-width: 350px;
        // padding-top: .6em;
        // padding-bottom: .5em;
    }
}

.p-8 {
    padding: 8rem;
}
.mt-sm-n10 {
    @include bp('tbdt'){
        margin-top: -3rem;
    }
    @include bp('lg'){
        margin-top: -8rem;
    }
    @include bp('xl'){
        margin-top: -12rem;
    }
}

@include bp('mbtb') {
    .mobileOnly {display: block;}
    .noMobile {display: none !important;}


    .main-wrapper .home .row#howtoenter {
        // padding: 2rem 0;
        padding-bottom: 7rem;
        .steps {
            padding:0px;
            margin-bottom: 0px;
            div {
                margin-bottom: 0px;

                // img {margin: 0 auto !important;}
            }
            p {
                padding: 0 2rem;
            }
        }
    } 
}
